<template>
  <window-content v-if="!loading && !errors && !success">
    <div class="m-t">
      <e-row class=" erp-row-col-md">
        <e-col style="max-width: 240px">
          <erp-s-field
              view="lr"
              label="ID:"
              label-width="110px"
          >
            <erp-input simple-border v-model="reboquista.id" disable />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 230px">
          <erp-s-field
              view="lr"
              label="Apelido:"
              label-width="50px"
          >
            <apelido-input :nome="reboquista.pessoa.name" :gerar-blur="!reboquista.id"
                           v-model="reboquista.apelido" />
          </erp-s-field>
        </e-col>

        <e-col>
          <tipo-reboquista-select label="Tipo:" field-view="lr" simple-border="" v-model="reboquista.pessoa.type" />
        </e-col>

        <e-col style="max-width: 260px">
          <status-select label="Status:" placeholder="Selecione" field-view="lr" simple-border=""
                         v-model="reboquista.status" />
        </e-col>

      </e-row>

      <e-row class=" erp-row-col-md">
        <e-col>
          <erp-s-field
              view="lr"
              :label="reboquista.pessoa.type === 1 ? 'Nome completo:' : 'Razão social:'"
              label-width="110px"
          >
            <erp-input simple-border v-model="reboquista.pessoa.name" />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 340px">
          <erp-s-field
              view="lr"
              :label="reboquista.pessoa.type === 1 ? 'CPF:' : 'CNPJ:'"
              label-width="40px"
          >
            <erp-input simple-border v-model="reboquista.pessoa.document" v-mask="'###.###.###-##'"
                       v-if="reboquista.pessoa.type === 1" />
            <erp-input simple-border v-model="reboquista.pessoa.document" v-mask="'##.###.###/####-##'" v-else />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row class=" erp-row-col-md">
        <e-col>
          <erp-s-field
              view="lr"
              label="E-mail:"
              label-width="110px"
          >
            <erp-input simple-border v-model="reboquista.pessoa.emails[0].email" />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 200px">
          <erp-s-field
              view="lr"
              label="Celular:"
              label-width="50px"
          >
            <erp-input simple-border v-model="reboquista.pessoa.phoneNumbers[0].phoneNumber"
                       v-mask="'(##) # ####-####'" />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 200px">
          <erp-s-field
              view="lr"
              label="Residencial:"
              label-width="70px"
          >
            <erp-input simple-border v-model="reboquista.pessoa.phoneNumbers[1].phoneNumber"
                       v-mask="'(##) ####-#####'" />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 200px">
          <erp-s-field
              view="lr"
              label="Comercial:"
              label-width="70px"
          >
            <erp-input simple-border v-model="reboquista.pessoa.phoneNumbers[2].phoneNumber"
                       v-mask="'(##) ####-#####'" />
          </erp-s-field>
        </e-col>

      </e-row>
    </div>
    <erp-tabs @change="changeTab" :active="tabActive">
      <erp-tab-item name="principal">Principal</erp-tab-item>
      <erp-tab-item name="contato">Endereço e Contato</erp-tab-item>
      <erp-tab-item name="reboques" v-if="reboquista.id">Reboques</erp-tab-item>
      <erp-tab-item name="motoristas" v-if="reboquista.id">Motoristas</erp-tab-item>
      <erp-tab-item name="cotacoes" v-if="reboquista.id">Cotações</erp-tab-item>
      <erp-tab-item name="remocoes" v-if="reboquista.id">Remoções</erp-tab-item>
      <!--<erp-tab-item name="planilhas">Planilha de custos</erp-tab-item>-->
      <erp-tab-item name="logs" v-if="reboquista.id">Logs</erp-tab-item>
    </erp-tabs>
    <window-body class="font-11" :class="{'flex col-grow': tabActive === 'logs' || tabActive === 'arremates'}" tabs>
      <div v-show="tabActive === 'principal'" class="wrapper m-t">
        <e-row>
          <e-col>
            <e-row v-if="isPessoaFisica" mr>
              <e-col style="min-width: 280px">
                <erp-s-field
                    view="lr"
                    label="RG:"
                    label-width="90px"
                >
                  <erp-input v-model="reboquista.pessoa.documents[0].identifier" />
                </erp-s-field>
              </e-col>

              <e-col>
                <erp-s-field
                    view="lr"
                    label="Emissor:"
                >
                  <erp-input v-model="reboquista.pessoa.documents[0].agentDispatcher" />
                </erp-s-field>
              </e-col>

              <e-col>
                <erp-s-field
                    view="lr"
                    label="Expedição:"
                >
                  <erp-input v-model="reboquista.pessoa.documents[0].expedition" />
                </erp-s-field>
              </e-col>
            </e-row>

            <e-row v-if="isPessoaFisica" mr>
              <e-col>
                <erp-s-field
                    view="lr"
                    label="Nascimento:"
                    label-width="90px"
                >
                  <erp-input v-model="reboquista.pessoa.birthDate" v-mask="'##/##/####'" />
                </erp-s-field>
              </e-col>

              <e-col>
                <erp-s-field
                    view="lr"
                    label="Nacionalidade:"
                >
                  <erp-input v-model="reboquista.pessoa.nationality" />
                </erp-s-field>
              </e-col>

              <e-col>
                <erp-s-field
                    view="lr"
                    label="Sexo:"
                >
                  <erp-select placeholder="Selecione"
                              :options="[{label: 'Masculino', value: 1},{label: 'Feminino', value: 2}]"
                              v-model="reboquista.pessoa.gender" />
                </erp-s-field>
              </e-col>
            </e-row>

            <e-row v-if="isEmpresa" mr>
              <e-col style="max-width: 260px">
                <erp-s-field
                    view="lr"
                    label="Proprietário:"
                    label-width="90px"
                >
                  <erp-input v-model="reboquista.pjProprietarioNome" />
                </erp-s-field>
              </e-col>

              <e-col>
                <erp-s-field
                    view="lr"
                    label="CPF:"
                >
                  <erp-input v-model="reboquista.pjProprietarioCpf" />
                </erp-s-field>
              </e-col>

              <e-col>
                <erp-s-field
                    view="lr"
                    label="RG:"
                >
                  <erp-input v-model="reboquista.pjProprietarioRg" />
                </erp-s-field>
              </e-col>
            </e-row>

            <e-row class="m-t-xs" mr>
              <e-col>
                <erp-s-field
                    view="lr"
                    label-width="90px"
                    tag="div"
                >
                  <erp-checkbox class="flex items-center" v-model="reboquista.podeReceberCotacoes">
                    <label class="no-select font-bold">
                      <span class="m-l-xs">Pode Receber Cotações</span>
                    </label>
                  </erp-checkbox>
                </erp-s-field>
              </e-col>
            </e-row>
            <e-row class="" mr>
              <e-col>
                <erp-s-field
                    view="lr"
                    label-width="90px"
                    tag="div"
                >
                  <erp-checkbox class="flex items-center" v-model="reboquista.atendeQualquerComitente">
                    <label class="no-select font-bold">
                      <span class="m-l-xs">Atende qualquer comitente</span>
                    </label>
                  </erp-checkbox>
                </erp-s-field>
              </e-col>
            </e-row>
            <!--<e-row class="" mr>
              <e-col>
                <erp-s-field
                    view="lr"
                    label-width="90px"
                    tag="div"
                >
                  <erp-checkbox class="flex items-center" v-model="reboquista.permitirCompraBlindado">
                    <label class="no-select font-bold">
                      <span class="m-l-xs">Habilitar compra de blindados</span>
                    </label>
                  </erp-checkbox>
                </erp-s-field>
              </e-col>
            </e-row>-->
            <e-row class="m-t-xs" mr>
              <e-col>
                <erp-s-field
                    view="lr"
                    label-width="90px"
                    tag="div"
                >
                  <erp-checkbox class="flex items-center" v-model="reboquista.newsletter">
                    <label class="no-select font-bold">
                      <span class="m-l-xs">Inscrito para newsletter</span>
                    </label>
                  </erp-checkbox>
                </erp-s-field>
              </e-col>
            </e-row>

            <e-row mr class="m-t">
              <e-col>
                <tipo-comitente-select label-width="90px" label="Cobertura:" field-view="lr" simple-border="" multiple v-model="reboquista.tipoComitenteAtendidos" />
              </e-col>
            </e-row>

            <e-row mr class="m-t">
              <e-col>
                <erp-s-field
                    view="lr"
                    label="Descrição interna:"
                    class="wrap label-wrap"
                >
                  <textarea class="w-full erp-input wrapper-xs" rows="4"
                            v-model="reboquista.observacao"></textarea>
                </erp-s-field>
              </e-col>
            </e-row>
          </e-col>
          <e-col class="m-l-xxl" style="min-width: 216px; max-width: 216px">
            <foto ref="foto" :arrematante="reboquista" @update="updateFoto" @toDelete="deleteFotoEvent" />
            <div class="text-center m-t-xs">
              <score :arrematante="reboquista" />
            </div>
          </e-col>
        </e-row>
        <e-row class="m-t">
          <e-col>
            <div class="font-bold m-b-sm">Acesso</div>
            <acesso :entidade="reboquista" />
          </e-col>
        </e-row>
        <e-row class="m-t">
          <e-col>
            <div class="font-bold m-b-sm font-11">Após o cadastro ou alteração:</div>
            <e-row>
              <e-col>
                <erp-checkbox class="flex no-wrap items-center m-r" v-model="reboquista.enviarBoasVindas">
                  <label class="no-select text-nowrap font-bold">
                    <span class="m-l-xs text-nowrap">Enviar e-mail de boas vindas</span>
                  </label>
                </erp-checkbox>

                <erp-checkbox class="flex no-wrap items-center m-r" v-model="reboquista.solicitarCriacaoSenha">
                  <label class="no-select text-nowrap font-bold">
                    <span class="m-l-xs text-nowrap">Solicitar criação de senha</span>
                  </label>
                </erp-checkbox>
              </e-col>
            </e-row>
          </e-col>
        </e-row>
      </div>
      <contato v-show="tabActive === 'contato'" class="wrapper m-t" :entidade="reboquista" />
      <div v-if="reboquista.id" v-show="tabActive === 'reboques'" class="wrapper m-t">
        <reboques :reboquista="reboquista" />
      </div>
      <div v-if="reboquista.id" v-show="tabActive === 'motoristas'" class="wrapper m-t"></div>
      <div v-if="reboquista.id" v-show="tabActive === 'cotacoes'" class="wrapper m-t"></div>
      <div v-if="reboquista.id" v-show="tabActive === 'remocoes'" class="wrapper m-t"></div>
      <!--<div v-show="tabActive === 'planilhas'" class="wrapper m-t"></div>-->
      <div v-if="reboquista.id && tabActive === 'logs'" class="wrapper flex col-grow">
        <log :usuario="reboquista" type="reboquista" />
      </div>
    </window-body>
    <window-footer class="flex content-between">
      <!--<div class="flex items-center justify-center font-11">
        <div class="m-l-xs">
          Ao salvar o leilão ele ficará com status Rascunho. Após validar as informações, altere o
          status para Em breve.
        </div>
      </div>-->
      <div class="inline-block window-actions text-right self-end col-grow">
        <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
               @click="$uloc.window.close($root.wid)" />
        <u-btn label="Salvar (F2)" icon="save" icon-type="fa" icon-style="light" flat no-caps
               @click="save"
               v-shortkey="['f2']" @shortkey.native="save" />
      </div>
    </window-footer>
  </window-content>
  <window-success v-else-if="success">
    <div class="text-center">
      <u-icon name="check-circle" color="positive" type="fa" icon-style="solid" class="success-circle-140" />
    </div>
    <h5 v-if="!id" class="m-t">Reboquista registrado com sucesso!</h5>
    <h5 v-else class="m-t">Reboquista atualizado com sucesso!</h5>
    <div class="text-center m-t">
      <timer-close-window ref="timer" @end="$uloc.window.close($root.wid)" />
    </div>
  </window-success>
  <window-loading :errors="errors" :status="status" v-else />
</template>

<script>
/* eslint-disable */
import {
  WindowContent,
  WindowBody,
  WindowFooter,
  ErpTabItem,
  ErpTabs,
  ErpSField,
  ErpInput,
  ErpLabel,
  ErpSelect,
  ErpCheckbox
} from 'uloc-vue-plugin-erp'
import WindowLoading from '../../../layout/window/Loading'
import TimerCloseWindow from '../../../layout/window/TimerClose'
import WindowSuccess from '../../../layout/window/Success'
import {date} from 'uloc-vue'
import {VMoney} from 'v-money'
import {REAL_BRL, convertRealToMoney} from '@/utils/money'
import {
  find,
  newReboquista,
  updateReboquista,
  updateFotoReboquista
} from '../../../../domain/reboquista/services/index'
import DatetimeInput from '../../../../reuse/input/Datetime'
import DefaultAsyncSelect from '../../../../reuse/input/DefaultAsyncSelect'
import StatusSelect from '../../../arrematante/components/include/StatusArrematanteSelect'
import PatioInput from '../../../patio/helpers/input/PatioInput'
import ERow from '../../../layout/components/Row'
import ECol from '../../../layout/components/Col'
import TipoReboquistaSelect from '../../../arrematante/components/include/TipoArrematanteSelect'
import ApelidoInput from '../../../arrematante/components/include/ApelidoInput'
import {EstadoCivil, ESTADO_CIVIL_CASADO} from '@/domain/arrematante/helpers/Status'
import Score from '../../../pda/components/apps/arrematante/Score'
import Acesso from '../include/Acesso'
import Log from '../../../usuario/components/include/Log'
import {adjustPersonData} from '../../../../domain/pessoa/helpers/Purpose'
import {TIPO_PESSOA_JURIDICA} from '@/domain/pessoa/helpers/Status'
import Foto from '../../../arrematante/components/include/Foto'
import Contato from '../../../pessoa/components/include/Contato'
// import Endereco from '../../../pessoa/components/include/Endereco'

import {mockEndereco} from '../../../pessoa/mock/endereco'
import {mockTelefone} from '../../../pessoa/mock/telefone'
import {mockEmail} from '../../../pessoa/mock/email'
import {mockContato} from '../../../pessoa/mock/contato'
import {mockDocument} from '../../../pessoa/mock/document'
import Reboques from '../include/Reboques'
import TipoComitenteSelect from "@/components/comitente/components/include/TipoComitenteSelect"

let mockReboquista = {
  id: null,
  apelido: null,
  podeReceberCotacoes: true,
  atendeQualquerComitente: false,
  newsletter: true,
  score: null,
  pjProprietarioCpf: null,
  pjProprietarioNome: null,
  pjProprietarioRg: null,
  pjProprietarioTelefoneComercial: null,
  observacao: null,
  pessoa: {
    id: null,
    name: null,
    type: 1,
    document: null,
    birthDate: null,
    nationality: null,
    gender: null,
    addresses: [JSON.parse(JSON.stringify(mockEndereco))],
    phoneNumbers: [JSON.parse(JSON.stringify(mockTelefone)), JSON.parse(JSON.stringify(mockTelefone)), JSON.parse(JSON.stringify(mockTelefone))],
    emails: [JSON.parse(JSON.stringify(mockEmail))],
    contacts: [JSON.parse(JSON.stringify(mockContato)), JSON.parse(JSON.stringify(mockContato)), JSON.parse(JSON.stringify(mockContato))],
    documents: [JSON.parse(JSON.stringify(mockDocument))]
  },
  enviarBoasVindas: true,
  solicitarCriacaoSenha: true,
  status: 0,
  tipoComitenteAtendidos: null
}

export default {
  name: 'ReboquistaWindow',
  directives: {money: VMoney},
  props: ['id'],
  data () {
    return {
      money: REAL_BRL,
      tabActive: 'principal',
      loading: !!this.id,
      errors: null,
      success: false,
      status: null,
      deleteFoto: false,
      reboquista: JSON.parse(JSON.stringify(mockReboquista))
    }
  },
  mounted () {
    this.load()
  },
  computed: {
    EstadoCivil () {
      return EstadoCivil
    },
    EstadoCivilCasado () {
      return ESTADO_CIVIL_CASADO
    },
    isPessoaFisica () {
      return this.reboquista.pessoa.type === 1
    },
    isEmpresa () {
      return this.reboquista.pessoa.type === 2
    }
  },
  watch: {},
  methods: {
    load () {
      this.id && find(this.id || this.reboquista.id)
        .then(response => {
          let data = response.data

          if (data.tipoComitenteAtendidos) {
            data.tipoComitenteAtendidos = data.tipoComitenteAtendidos.map(t => t.id)
          }

          this.reboquista = adjustPersonData(data)
          this.$nextTick(() => {
            this.loading = false
          })
        })
        .catch((error) => {
          this.$uloc.dialog({
            title: 'Falha ao carregar!',
            color: 'negative',
            message: `Não conseguimos carregar o arremtante, entre em contato com o administrador.`
          })
          this.loading = false
          this.errors = error.data
          console.error(error, error.data)
        })
    },
    changeTab ({tab}) {
      this.tabActive = tab
    },
    save () {
      console.log('Save')
      this.loading = true
      let data = JSON.parse(JSON.stringify(this.reboquista))

      // Ajusta dados
      let phones = []
      this.reboquista.pessoa.phoneNumbers.forEach((phone, index) => {
        if (phone.phoneNumber && String(phone.phoneNumber).length > 4) {
          phones.push(phone)
        }
      })
      data.pessoa.phoneNumbers = phones

      let contacts = []
      this.reboquista.pessoa.contacts.forEach((contact, index) => {
        if (contact.value && String(contact.value).length > 2) {
          contacts.push(contacts)
        }
      })
      data.pessoa.contacts = contacts

      data.pessoa.addresses.map((address) => {
        if (address.cityId && address.cityId.id) {
          address.cityId = address.cityId.id
        }
        if (address.city && address.city.label) {
          address.city = address.city.label
        }
      })

      data.pessoa.documents && data.pessoa.documents.map((document) => {
        if (document.type && document.type.id) {
          document.type = document.type.id
        }
      })

      if (data.pessoa.type === TIPO_PESSOA_JURIDICA) {
        // data.pessoa.documents = null
      }

      this.status = 'Processando dados do reboquista'
      let method = data.id ? updateReboquista(data.id, data) : newReboquista(data)
      method
        .then(({data}) => {
          this.reboquista.id = data.id
          const cbSuccess = () => {
            this.success = true
            this.$nextTick(() => {
              // this.$refs.timer.start()
              window.setTimeout(() => {
                this.loading = false
                this.success = false
                this.load()
              }, 3000)
              this.$uloc.window.emit(this.$root.wid, 'update', this.props)
            })
          }
          // Processa foto
          if (this.deleteFoto) {
            console.log('Deleta Foto')
          } else if (this.$fotoReboquista) {
            this.status = 'Analisando e processando foto do reboquista'
            updateFotoReboquista(this.reboquista.id, {data: this.$fotoReboquista, filename: 'foto'})
              .then(({data}) => {
                cbSuccess()
                delete this.$fotoReboquista
              })
              .catch((error) => {
                alert('Impossível enviar a foto do reboquista, mas os demais dados foram processados com sucesso!')
                console.error(error)
                cbSuccess()
              })
          } else {
            cbSuccess()
          }

        })
        .catch(error => {
          console.log(error)
          this.success = false
          this.errorMessage = error.data.message
          // this.errors = this.systemError(error)
          this.$nextTick(() => {
            this.loading = false
            this.alertApiError(error)
            // this.error = true
          })
        })
    },
    deleteFotoEvent () {
      this.deleteFoto = true
    },
    updateFoto (file) {
      this.$fotoReboquista = file
    }
  },
  components: {
    Reboques,
    // Endereco,
    Contato,
    Foto,
    Log,
    Acesso,
    Score,
    ApelidoInput,
    TipoReboquistaSelect,
    ECol,
    ERow,
    PatioInput,
    StatusSelect,
    DefaultAsyncSelect,
    WindowSuccess,
    TimerCloseWindow,
    WindowLoading,
    WindowFooter,
    WindowBody,
    WindowContent,
    ErpTabs,
    ErpTabItem,
    ErpSField,
    ErpInput,
    ErpLabel,
    ErpSelect,
    DatetimeInput,
    ErpCheckbox,
    TipoComitenteSelect
  }
}
</script>
