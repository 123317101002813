<template>
  <div>
    <div class="flex col-grow justify-between" style="height: 160px !important;">
      <div class="col-grow flex justify-between h-full">
        <e-window-table fixed class="col-grow h-full"
                        :columns="['ID', 'Placa', 'Opções']"
        >
          <e-tr v-for="(data, index) in reboques" :key="index">
            <e-td>{{data.id}}</e-td>
            <e-td>{{data.placa}}</e-td>
            <e-td><a @click="open(data.id)"><i class="erp-icon search min"></i> </a></e-td>
          </e-tr>
          <e-tr v-if="!reboques || !reboques.length">
            <e-td style="text-align: left !important;" colspan="100%">
              Nenhum registro
            </e-td>
          </e-tr>
        </e-window-table>
      </div>
    </div>
    <e-table-pagination class="m-t-xs" v-model="page" :max="max" />
  </div>
</template>

<script>
import {ETd, ETr, EWindowTable, ETablePagination} from 'uloc-vue-plugin-erp'
import {listReboques} from '@/domain/reboquista/services'

export default {
  name: 'Reboques',
  props: {
    reboquista: {
      required: true
    }
  },
  components: {
    EWindowTable,
    ETr,
    ETd,
    ETablePagination
  },
  data () {
    return {
      loading: false,
      reboques: null,
      page: 1,
      max: 1
    }
  },
  mounted () {
    this.load()
  },
  computed: {},
  methods: {
    load () {
      this.loading = true
      listReboques(this.reboquista.id, 50, 1, '')
        .then(({data}) => {
          if (!Array.isArray(data.result)) {
            return
          }
          this.max = Number(data.total)
          this.reboques = data.result
          this.loading = false
        })
        .catch(error => {
          this.loading = false
          this.alertApiError(error)
        })
    },
    open (id) {
      console.log('open...')
    }
  }
}
</script>
