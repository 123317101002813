<template>
  <div>
    <!--<div class=" flex justify-between" style="min-height: 160px !important;">-->
    <div class="col-grow flex justify-between h-full">
      <!-- TODO: BUG: Window table need an max-height prop and ajsut scroll and thead -->
      <e-window-table class="col-grow h-full"
                      :columns="['ID', 'Login', 'Perfil', 'Último acesso', 'Status', 'Opções']"
      >
        <e-tr v-for="(data, index) in usuarios" :key="index">
          <e-td><span>{{ String(data.usuario.id).padStart(4, '0') }}</span></e-td>
          <e-td>{{ data.usuario.username }}</e-td>
          <e-td>
            <span v-if="!data.permissao">Administrador</span>
            <span v-else>Motorista</span>
          </e-td>
          <e-td><span v-if="data.lastLogin">{{ data.usuario.lastLogin.date|formatDate('dd/MM/yyyy HH:mm:ss') }}</span><span v-else>Nunca</span>
          </e-td>
          <e-td>
            <div v-if="data.usuario.active" class="flex items-center text-positive">
              <e-btn-circle-status status="positive" class="sm m-r-xs" />
              Ativo
            </div>
            <div v-else>
              <e-btn-circle-status status="negative" class="sm m-r-xs" />
              Inativo
            </div>
          </e-td>
          <e-td><a @click="open(data.usuario.id)"><i class="erp-icon search min"></i> </a></e-td>
        </e-tr>
        <e-tr v-if="!usuarios || !usuarios.length">
          <e-td style="text-align: left !important;" colspan="100%">
            Nenhum registro
          </e-td>
        </e-tr>
      </e-window-table>
    </div>
    <!--</div>-->
  </div>
</template>

<script>
import {ETd, ETr, EWindowTable, EBtnCircleStatus} from 'uloc-vue-plugin-erp'
import windowUsuarioShow from '../../../usuario/window/windowUsuarioShow'

export default {
  name: 'Acesso',
  props: {
    entidade: {
      required: true
    }
  },
  components: {
    EWindowTable,
    ETr,
    ETd,
    EBtnCircleStatus
  },
  data () {
    return {}
  },
  computed: {
    usuarios () {
      return this.entidade.usuarios
    }
  },
  methods: {
    status (s) {
      if (typeof s !== 'undefined') {
        return String(s)
      }
      return false
    },
    open (id) {
      this.windowUsuarioShow(id)
    },
    windowUsuarioShow: windowUsuarioShow
  }
}
</script>
